// ---------- ---------- ---------- ---------- ----------
// REBOOT
// ---------- ---------- ---------- ---------- ----------
*, *::before, *::after { box-sizing:border-box; }

// HTML / BODY
html { font-size:100%; }
body { margin:0; font-family:$font-family-base; font-size:$font-size-base; line-height:$line-height-base; color:$txt-color-base; background-color:$bg-color-base; font-weight:normal; -webkit-text-size-adjust:100%; }

// Reset margins on paragraphs
p { margin-top:0; margin-bottom:1rem; }

// Address
address { margin-bottom:1rem; font-style:normal; line-height:inherit; }

// Lists
ol, ul { padding-left:2rem; }
ol, ul, dl { margin-top:0; margin-bottom:1rem; }
ol ol, ul ul, ol ul, ul ol { margin-bottom:0; }

dt { font-weight:700; }
dd { margin-bottom:.5rem; margin-left:0; }

// Links
a {
  color:$link-color; text-decoration:$link-decoration;
  &:hover { color:$link-hover-color; text-decoration:$link-hover-decoration;
  }
}

a:not([href]):not([class]) {
  &, &:hover { color:inherit; text-decoration:none; }
}

// Figures
figure { margin:0 0 1rem; }

// Images and content
img, svg { vertical-align:middle; }

// Forms
label { display:inline-block; }
button { border-radius:0; }
button:focus:not(:focus-visible) { outline:0; }

input, button, select, optgroup, textarea { margin:0; font-family:inherit; font-size:inherit; line-height:inherit; }
button, select { text-transform:none; }
[role="button"] { cursor:pointer; }

button, [type="button"], [type="reset"], [type="submit"] {
  &:not(:disabled) { cursor:pointer; }
}

::-moz-focus-inner {
  padding:0;
  border-style:none;
}

::-webkit-search-decoration { -webkit-appearance:none; }
::-webkit-color-swatch-wrapper { padding:0; }