@import
    "common/variables"
    ,"common/fonts"
    ,"common/reboot"
;

.hidden-mobile {
    @media (max-width:767px) { display:none; }
}

// ---------- ---------- ---------- ---------- ----------
// WRAPPER
// ---------- ---------- ---------- ---------- ----------
.wrapper {
    display:flex; flex-direction:column; min-height:100vh; position:relative; z-index:1; background-image:var(--bg); background-size:cover; background-repeat:no-repeat; background-position:50% 50%; 
}

// ---------- ---------- ---------- ---------- ----------
// HEADER
// ---------- ---------- ---------- ---------- ----------
.header {
    text-align:center; padding:$pad; color:$txt-color-inverse;
}
.logo {
    display:flex; flex-direction:column; align-items:center;
    &-svg { 
        width:3rem; height:3rem; fill:white;
        @media (max-width:767px) { width:2rem; height:2rem; }
    }
    &-txt {
        padding:0.5rem 0 0 0; text-transform:uppercase; font-weight:bold;
        @media (max-width:767px) { font-size:0.875rem; }
    }
}

// ---------- ---------- ---------- ---------- ----------
// MAIN
// ---------- ---------- ---------- ---------- ----------
.main {
    flex:1; text-align:center; display:flex; align-items:center; justify-content:center; padding:$pad; color:$txt-color-inverse; flex-direction:column;
    @media (max-width:767px) { padding-bottom:4rem; }
}

.figure {
    margin:0 auto 0.75rem auto; width:8rem; height:8rem; border-radius:50%; display:block; overflow:hidden;
    @media (max-width:767px) { width:6rem; height:6rem; }
    img { width:100%; }
}
.title {
    margin:0 0 3rem 0; padding:0; font-weight:bold; font-size:2rem; text-shadow:$text-shadow;
    @media (max-width:767px) { margin:0 0 2rem 0; font-size:1.5rem; }
    &-sub { 
        display:block; font-size:1rem; text-transform:uppercase;
        @media (max-width:767px) { font-size:0.75rem; }
    }
}
cite {
    margin:0 0 1.5rem 0; font-size:1.25rem; font-style:italic; text-shadow:$text-shadow;
    @media (max-width:767px) { font-size:1rem; }
}
.btn {
    display:inline-block; padding:0.75rem $pad-s; font-size:0.875rem; line-height:1.2; font-weight:bold; background-color:var(--brand); color:white; text-align:center; text-decoration:none; vertical-align:middle; cursor:pointer; border:none; border-radius:$radius; transition:$transition; margin:0.375rem; text-transform:uppercase;
    &:hover { opacity:0.8; color:white; }
    svg { fill:currentColor; width:1.25rem; height:1.25rem; vertical-align:middle; position:relative; top:-1px; margin-right:0.125rem; line-height:1.4; }
}

// ---------- ---------- ---------- ---------- ----------
// PANO
// ---------- ---------- ---------- ---------- ----------
.pano { 
    position:fixed; top:0; width:var(--pano-w); height:100vh; z-index:9999; background-color:white; transition:$transition; box-shadow:$shadow; display:none;
    &.left {
        left:calc(-1 * $pano-w); display:block;
        @media (max-width:767px) { left:-100vw; }
        .pano-open & { left:0; }
    }
    &.right {
        right:calc(-1 * $pano-w); display:block;
        @media (max-width:767px) { right:-100vw; }
        .pano-open & { right:0; }
    }
    @media (max-width:767px) { width:100% !important; }

    &-overlay {
        position:fixed; width:100%; height:100%; display:none; z-index:2000; top:0; left:0; backdrop-filter:blur(0.5rem); transition:$transition;
        .pano-open & { display:block; }
    }

    &-nav {
        display:block; width:$pano-inter-w; position:absolute; top:50%; border:none; padding:0; transition:$transition; z-index:1000; transform:translateY(-50%);
        .pano.left & { 
            right:calc(-1 * $pano-inter-w); 
            .pano-open & { right:calc(-1 * $pano-inter-w); }
        }
        .pano.right & { 
            left:calc(-1 * $pano-inter-w);
            .pano-open & { left:calc(-1 * $pano-inter-w); }
        }
        @media (max-width:767px) { width:100% !important; position:fixed; bottom:0 !important; left:0 !important; right:0 !important; top:auto !important; z-index:99999; transform:none !important; border-top:1px solid $border-color; }
        &-list { 
            padding:0; margin:0; list-style:none;
            @media (max-width:767px) { display:flex; }
        }
        &-link { 
            display:block; display:block; width:$pano-inter-w; height:$pano-inter-w; background:var(--brand); border:none; padding:0; margin:0; transition:$transition; border-top:1px solid white;
            svg { width:1.5rem; height:1.5rem; fill:white; transition:$transition; }
            &.current { 
                background-color:white !important;
                svg { fill:var(--brand) !important; }
            }
            &:hover {
                background-color:white;
                svg { fill:var(--brand); }
            }
            @media (max-width:767px) { width:100%; border-top:none; border-left:1px solid white; }
        }
        &-item {
            &:first-child { 
                .pano-nav-link {
                    border-top:none; border-left:none;
                    .pano.left & { border-top-right-radius:$radius; }
                    .pano.right & { border-top-left-radius:$radius; }
                    @media (max-width:767px) { border-top-left-radius:0 !important; border-top-right-radius:0 !important; }
                }
            }
            &:last-child { 
                .pano-nav-link {
                    .pano.left & { border-bottom-right-radius:$radius; }
                    .pano.right & { border-bottom-left-radius:$radius; }
                    @media (max-width:767px) { border-bottom-left-radius:0 !important; border-bottom-right-radius:0 !important; }
                }
            }
            @media (max-width:767px) { flex:1; }
        }
    }

    &-header {
        padding:$pad-s 2.5rem $pad-s $pad-s; border-bottom:1px solid $gris-extralight;
        &-title { font-size:1.125rem; margin:0; text-transform:uppercase; font-weight:bold; }
        .pano.left & { border-top-right-radius:$radius; }
        .pano.right & { border-top-left-radius:$radius; }
        svg { width:1.5rem; height:1.5rem; margin-right:0.25rem; fill:$txt-color-base; position:relative; top:-2px; }
    }

    &-i { 
        /*display:none;*/ height:100%;
        &2 {
            display:flex; height:100%; flex-direction:column;
        }
    }

    &-close { 
        position:absolute; top:1rem; right:1rem; width:1.5rem; height:1.5rem; padding:0; margin:0; background-color:transparent; border:none;
        svg { width:1.5rem; height:1.5rem; fill:var(--brand); transition:$transition; }
        &:hover {
            svg { fill:$txt-color-base; }
        }
    }

    &-content { 
        flex:1; overflow-y:auto; padding:$pad-s;
        @media (max-width:767px) { padding-bottom:4rem !important; }
        &::-webkit-scrollbar { display:none; }
    }

    &-section { border-bottom:1px solid $border-color; padding:0 0 $pad-s 0; margin:0 0 $pad-s 0;}

    &-toggle {
        position:relative; margin-bottom:1rem;
        input[type="checkbox"] {
            position:absolute; left:-9999em;
            ~ label {
                display:block; cursor:pointer; width:100%; padding-left:3rem; color:$txt-color-base; margin-bottom:0.25rem;
                &:before, &:after { position:absolute; display:block; content:''; transition:all 0.2s linear; top:50%; transform:translateY(-50%); }
                &:before { background-color:$gris; left:0; width:2.5rem; height:1.25rem; border-radius:0.624rem; border:1px solid $gris; }
                &:after { background-color:$gris-extralight; left:0.125rem; width:1rem; height:1rem; border-radius:50%; }
            }
            &:checked ~ label { 
                color:$txt-color-base; font-weight:bold;
                &:before { background-color:var(--brand); border-color:var(--brand); }
                &:after { left:22px; background-color:white; }
            }
            &:focus-visible {
                ~ label { 
                    &:before { outline:4px solid tomato; }
                }
            }
        }
    }

    &-theme {
        &-title { margin:0 0 0.5rem 0; }
        &-liste { display:flex; flex-wrap:wrap; padding:0; margin:0; list-style:none; }
        &-item {
            display:inline-block; margin:0 0.5rem 0.5rem 0; padding:0; list-style:none; 
            input[type="radio"] {
                position:absolute; left:-9999em;
                &:checked, &:not(:checked) {
                    ~ label {
                        display:inline-block; width:4rem; height:3rem; cursor:pointer; margin:0; border-radius:$radius; position:relative; transition:$transition;
                    }
                }
                &:checked {
                    ~ label { 
                        &:after { 
                            display:block; position:absolute; content:""; top:50%; left:50%; transform:translate(-50%,-50%); height:2rem;
                            width:2rem; background-size:cover;
                            background-image:url('data:image/svg+xml,<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M55.3511 11.4393L20.3492 46.4412L7.97481 34.0668C7.38906 33.4811 6.43931 33.4811 5.85344 34.0668L4.43931 35.4809C3.85356 36.0667 3.85356 37.0164 4.43931 37.6023L19.2886 52.4516C19.8743 53.0373 20.8241 53.0373 21.4099 52.4516L58.8866 14.9749C59.4723 14.3892 59.4723 13.4394 58.8866 12.8536L57.4723 11.4393C56.8866 10.8536 55.9368 10.8536 55.3511 11.4393Z" fill="white"/></svg>');
                        }
                    }
                }
                &:focus-visible {
                    ~ label { 
                        outline:4px solid tomato;
                    }
                }
            }
        }
    }

    &-bg {
        &-title { margin:0 0 0.5rem 0; }
        &-liste { display:flex; flex-wrap:wrap; padding:0; margin:0; list-style:none; }
        &-item {
            display:inline-block; margin:0 0.5rem 0.5rem 0; padding:0; list-style:none;
            input[type="radio"] {
                position:absolute; left:-9999em;
                &:checked, &:not(:checked) {
                    ~ label {
                        display:inline-block; width:8rem; height:6rem; cursor:pointer; margin:0; border-radius:$radius; position:relative; transition:$transition; background-size:cover; background-repeat:no-repeat; background-position:50% 50%;
                    }
                }
                &:checked {
                    ~ label { 
                        &:after { 
                            display:block; position:absolute; content:""; top:50%; left:50%; transform:translate(-50%,-50%); height:2rem;
                            width:2rem; background-size:cover;
                            background-image:url('data:image/svg+xml,<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M55.3511 11.4393L20.3492 46.4412L7.97481 34.0668C7.38906 33.4811 6.43931 33.4811 5.85344 34.0668L4.43931 35.4809C3.85356 36.0667 3.85356 37.0164 4.43931 37.6023L19.2886 52.4516C19.8743 53.0373 20.8241 53.0373 21.4099 52.4516L58.8866 14.9749C59.4723 14.3892 59.4723 13.4394 58.8866 12.8536L57.4723 11.4393C56.8866 10.8536 55.9368 10.8536 55.3511 11.4393Z" fill="white"/></svg>');
                        }
                    }
                }
                &:focus-visible {
                    ~ label { 
                        outline:4px solid tomato;
                    }
                }
            }
        }
    }

}

.dl { padding:0; margin:0 0 $pad 0; }
.dt { display:block; font-weight:bold; text-transform:uppercase; font-size:0.875rem; }
.dd { display:block; }
.ml { font-size:0.875rem; font-style:italic; }

.tag {
    padding:0.25rem 0.75rem; display:block; border-radius:$radius; background-color:olive; font-size:0.875rem; color:white; text-transform:uppercase;
    &-list { padding:0; margin:0 0 -0.5rem 0; list-style:none; }
    &-item { padding:0; margin:0; list-style:none; display:inline-block; margin:0 0.375rem 0.5rem 0; }
    &-1 { background-color:var(--brand-2); }
    &-2 { background-color:var(--brand-4); }
    &-3 { background-color:var(--brand-5); }
}

.logo {
    &-list { padding:0; margin:0; list-style:none; display:flex; flex-wrap:wrap; justify-content:center; }
    &-item { display:inline-block; width:5rem; height:5rem; margin:0.25rem; }
    &-img { width:100%; }
}