// ---------- ---------- ---------- ---------- ----------
// FONT
// ---------- ---------- ---------- ---------- ----------
$fontfolder:                    '../fonts/';

/* Typo */
$font-family-base:              'Raleway', 'Helvetica Neue', Arial, sans-serif !default;
$font-size-base:                1rem !default; // 16px
$line-height-base:              1.5 !default;

// ---------- ---------- ---------- ---------- ----------
// COLORS
// ---------- ---------- ---------- ---------- ----------
$gris-dark:                     #575757;
$gris:                          #999999;
$gris-extralight:               #e5e5e5;

$orange-dark:                   #de3700;
$orange:                        #ff4100;

$bleu-dark:                     #417c9e;
$bleu:                          #2c91af;

$mauve:                         #79517b;
$vert:                          olive;

$bg-color-base:                 white;
$txt-color-base:                $gris-dark;
$txt-color-inverse:             white;
$border-color:                  $gris-extralight;

$brand-1:                       $bleu;
$brand-2:                       $bleu-dark;
$brand-3:                       $orange;
$brand-4:                       $orange-dark;
$brand-5:                       $gris-dark;
$brand-6:                       $mauve;
$brand-7:                       $vert;
$brand-8:                       black;

// ---------- ---------- ---------- ---------- ----------
// BG
// ---------- ---------- ---------- ---------- ----------
$pad-s:                         1rem;
$pad:                           1.5rem;
$pad-l:                         2rem;

$radius-s:                      0.25rem;
$radius:                        0.5rem;
$radius-l:                      1rem;

$pano-inter-w:                  3rem;
$pano-w:                        36rem;
$pano-w-mobile:                 16rem;

$transition:                    all .25s ease-in-out 0s;
$shadow:                        0 0 2rem 0 rgb(41 48 66 / 20%);
$text-shadow:                   $gris-dark 0 0 10px;
$overlay:                       rgba(0, 0, 0, 0.5);

// link
$link-color:                  var(--brand) !default;
$link-decoration:             underline !default;
$link-shade-percentage:       20% !default;
$link-hover-color:            $txt-color-base !default;
$link-hover-decoration:       null !default;