// ---------- ---------- ---------- ---------- ----------
// RALEWAY
// ---------- ---------- ---------- ---------- ----------

/* 400 */
@font-face {
    font-family: 'Raleway'; font-display: swap;
    font-weight: 400; font-style: normal;
    src: url('#{$fontfolder}raleway-regular.woff2') format('woff2');
  }
/* 400 italic */
@font-face {
    font-family: 'Raleway'; font-display: swap;
    font-weight: 400; font-style: italic;
    src: url('#{$fontfolder}raleway-italic.woff2') format('woff2'); 
}

/* 700 */
@font-face {
    font-family: 'Raleway'; font-display: swap;
    font-weight: 700; font-style: normal;
    src: url('#{$fontfolder}raleway-700.woff2') format('woff2'); 
}